import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { UiHeading } from '../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "uiheading"
    }}>{`UiHeading`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/text/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`Component to render texts`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/text`}</p>
    </blockquote>
    <h2 {...{
      "id": "uiheading-1"
    }}>{`UiHeading`}</h2>
    <Playground __position={1} __code={'<UiHeading level={1}>Some heading</UiHeading>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiHeading,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiHeading level={1} mdxType="UiHeading">Some heading</UiHeading>
    </Playground>
    <h2 {...{
      "id": "uiheading-with-inverse-coloration"
    }}>{`UiHeading with inverse coloration`}</h2>
    <Playground __position={2} __code={'<UiHeading level={1} inverseColoration>\n  Some heading\n</UiHeading>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiHeading,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiHeading level={1} inverseColoration mdxType="UiHeading">
    Some heading
  </UiHeading>
    </Playground>
    <h2 {...{
      "id": "uiheading-centered"
    }}>{`UiHeading centered`}</h2>
    <Playground __position={3} __code={'<UiHeading level={1} centered>\n  Some heading\n</UiHeading>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiHeading,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiHeading level={1} centered mdxType="UiHeading">
    Some heading
  </UiHeading>
    </Playground>
    <h2 {...{
      "id": "uiheading-wrapped"
    }}>{`UiHeading wrapped`}</h2>
    <Playground __position={4} __code={'<UiHeading level={1} wrap>\n  This is a very very very very very loooooong heading so it can be wrapped\n</UiHeading>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiHeading,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiHeading level={1} wrap mdxType="UiHeading">
    This is a very very very very very loooooong heading so it can be wrapped
  </UiHeading>
    </Playground>
    <h2 {...{
      "id": "uiheading-with-default-coloration"
    }}>{`UiHeading with default coloration`}</h2>
    <Playground __position={5} __code={'<UiHeading level={1} coloration=\"dark\">\n  Will always render with DARK coloration\n</UiHeading>\n<UiHeading level={1} coloration=\"light\">\n  Will always render with LIGHT coloration\n</UiHeading>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiHeading,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiHeading level={1} coloration="dark" mdxType="UiHeading">
    Will always render with DARK coloration
  </UiHeading>
  <UiHeading level={1} coloration="light" mdxType="UiHeading">
    Will always render with LIGHT coloration
  </UiHeading>
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={UiHeading} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      